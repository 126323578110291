import { DefaultOptionType } from 'antd/lib/select';
import {
  addDays,
  endOfYear,
  getISOWeek,
  monthsInYear,
  startOfISOWeek
} from 'date-fns';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { PeriodType } from '~/constants/PeriodType';
import { capitalizeFirstLetter } from '~/utils/capitalizeFirstLetter';

function getWeeksPerCurrentYear() {
  const firstDayOfLastWeekInCurrentYear = startOfISOWeek(endOfYear(new Date()));
  const prevFirstDayOfLastWeekInCurrentYear = addDays(
    firstDayOfLastWeekInCurrentYear,
    -1
  );

  return Math.max(
    getISOWeek(firstDayOfLastWeekInCurrentYear),
    getISOWeek(prevFirstDayOfLastWeekInCurrentYear)
  );
}

function generateWeekOptions() {
  return Array.from({ length: getWeeksPerCurrentYear() }).map(
    (_, index): DefaultOptionType => {
      const label = index + 1;

      return { label, value: label };
    }
  );
}

const YEAR = new Date().getFullYear();

function generateMonthOptions(locale: string) {
  return Array.from({ length: monthsInYear }).map(
    (_, index): DefaultOptionType => {
      const value = index + 1;

      return {
        label: capitalizeFirstLetter(
          new Date(YEAR, index, 1).toLocaleString(locale, { month: 'long' })
        ),
        value
      };
    }
  );
}

export function usePeriodOptions(periodType: string) {
  const { language } = useParams();

  return useMemo(
    () =>
      periodType === PeriodType.WEEKLY
        ? generateWeekOptions()
        : generateMonthOptions(language),
    [language, periodType]
  );
}
