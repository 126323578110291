import { TableCell } from '@retail/backoffice-ui/src/PaginatedTable/TableCell';
import { TableColumnType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UserProjection } from '~/apollo/gql-types';
import { isNil } from '~/utils/boolean';

import { OutOfNetFinancing } from '../OutOfNet/OutOfNetFinancing';
import { OutOfNetOffered } from '../OutOfNet/OutOfNetOffered';
import { OutOfNetSignings } from '../OutOfNet/OutOfNetSignings';
import { ValuePercentTableCell } from '../ValuePercentTableCell';
import { PercentModel, SalesScoreboardModel } from '../types';

enum DataIndexToExclude {
  Place = 'place',
  SalesAgent = 'salesAgent'
}

export function useColumns() {
  const { t } = useTranslation();

  return useMemo((): TableColumnType<SalesScoreboardModel>[] => {
    const config: TableColumnType<SalesScoreboardModel>[] = [
      {
        dataIndex: DataIndexToExclude.Place,
        title: t('bo.salesScoreboard.table.column.place'),
        render: (value) => <TableCell data-qa="place">{value}</TableCell>
      },
      {
        dataIndex: DataIndexToExclude.SalesAgent,
        title: t('bo.salesScoreboard.table.column.salesAgent'),
        render: (value: UserProjection, { countryCode, children }) =>
          children?.length ? (
            t('bo.salesScoreboard.table.column.total')
          ) : (
            <TableCell data-qa="salesAgent">
              {[value?.firstName, value?.lastName].filter(Boolean).join(' ') ||
                t('bo.salesScoreboard.table.column.salesAgent.unassigned', {
                  country: t(`bo.common.country.${countryCode}`)
                })}
            </TableCell>
          )
      },
      {
        dataIndex: ['salesAgent', 'team'],
        title: t('bo.admin.userTeams.table.team'),
        sorter: (a, b) => {
          const teamA = a.salesAgent?.team;
          const teamB = b.salesAgent?.team;

          if (teamA == teamB) {
            return 0;
          }
          if (isNil(teamA)) {
            return 1;
          }
          if (isNil(teamB)) {
            return -1;
          }

          return teamA.localeCompare(teamB);
        },
        render: (value) => (
          <TableCell data-qa="team">
            {t(`bo.admin.userTeams.option.team.${value}`, {
              defaultValue: value
            })}
          </TableCell>
        )
      },
      {
        dataIndex: 'score',
        sorter: (a, b) => a.score - b.score,
        title: t('bo.salesScoreboard.table.column.score'),
        render: (value) => <TableCell data-qa="score">{value}</TableCell>
      },
      {
        dataIndex: 'netSignings',
        sorter: (a, b) => a.netSignings - b.netSignings,
        title: t('bo.salesScoreboard.table.column.netSignings'),
        render: (value) => <TableCell data-qa="netSignings">{value}</TableCell>
      },
      {
        dataIndex: ['meta', 'financingSignings'],
        sorter: (a, b) => a.financingSignings - b.financingSignings,
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.financingPercentOfNetSignings'
            )}
          />
        ),
        render: (value) => (
          <ValuePercentTableCell
            {...value}
            data-qa="financingPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: ['meta', 'ppiInsuredFinancingSignings'],
        sorter: (a, b) =>
          a.ppiInsuredFinancingSignings - b.ppiInsuredFinancingSignings,
        title: (
          <OutOfNetFinancing
            title={t(
              'bo.salesScoreboard.table.column.ppiInsuredPercentOfNetFinancingSignings'
            )}
          />
        ),
        render: (value) => (
          <ValuePercentTableCell
            {...value}
            data-qa="ppiInsuredPercentOfNetFinancingSignings"
          />
        )
      },
      {
        dataIndex: ['meta', 'gapInsuredFinancingSignings'],
        sorter: (a, b) =>
          a.gapInsuredFinancingSignings - b.gapInsuredFinancingSignings,
        title: (
          <OutOfNetFinancing
            title={t(
              'bo.salesScoreboard.table.column.gapInsuredPercentOfNetFinancingSignings'
            )}
          />
        ),
        render: (value) => (
          <ValuePercentTableCell
            {...value}
            data-qa="gapInsuredPercentOfNetFinancingSignings"
          />
        )
      },
      {
        dataIndex: ['meta', 'premiumWarranties'],
        sorter: (a, b) => a.premiumWarranties - b.premiumWarranties,
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.premiumWarrantiesPercentOfNetSignings'
            )}
          />
        ),
        render: (value) => (
          <ValuePercentTableCell
            {...value}
            data-qa="premiumWarrantiesPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: ['meta', 'premium12Warranties'],
        sorter: (a, b) => a.premium12Warranties - b.premium12Warranties,
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.premium12WarrantiesPercentOfNetSignings'
            )}
          />
        ),
        render: (value) => (
          <ValuePercentTableCell
            {...value}
            data-qa="premium12WarrantiesPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: ['meta', 'premium24Warranties'],
        sorter: (a, b) => a.premium24Warranties - b.premium24Warranties,
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.premium24WarrantiesPercentOfNetSignings'
            )}
          />
        ),
        render: (value) => (
          <ValuePercentTableCell
            {...value}
            data-qa="premium24WarrantiesPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: ['meta', 'premium36Warranties'],
        sorter: (a, b) => a.premium36Warranties - b.premium36Warranties,
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.premium36WarrantiesPercentOfNetSignings'
            )}
          />
        ),
        render: (value) => (
          <ValuePercentTableCell
            {...value}
            data-qa="premium36WarrantiesPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: ['meta', 'secondaryWheels'],
        sorter: (a, b) => a.secondaryWheels - b.secondaryWheels,
        title: (
          <OutOfNetOffered
            title={t(
              'bo.salesScoreboard.table.column.secondaryWheelsPercentOfNetSignings'
            )}
          />
        ),
        render: (value: PercentModel) =>
          value?.percent ? (
            <ValuePercentTableCell
              {...value}
              data-qa="secondaryWheelsPercentOfNetSignings"
            />
          ) : (
            <TableCell data-qa="cancelledSignings">{value?.value}</TableCell>
          )
      },
      {
        dataIndex: ['meta', 'tradeIns'],
        sorter: (a, b) => a.tradeIns - b.tradeIns,
        title: (
          <OutOfNetSignings
            title={t(
              'bo.salesScoreboard.table.column.tradeInsPercentOfNetSignings'
            )}
          />
        ),
        render: (value) => (
          <ValuePercentTableCell
            {...value}
            data-qa="tradeInsPercentOfNetSignings"
          />
        )
      },
      {
        dataIndex: 'cancelledSignings',
        title: t('bo.salesScoreboard.table.column.cancelledSignings'),
        sorter: (a, b) => a.cancelledSignings - b.cancelledSignings,
        render: (value) => (
          <TableCell data-qa="cancelledSignings">{value}</TableCell>
        )
      }
    ];

    return config.map(
      (x): TableColumnType<SalesScoreboardModel> => ({
        ...x,
        align: 'center'
      })
    );
  }, [t]);
}
