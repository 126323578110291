import { UserContextProvider } from '@retail/backoffice-ui/src/UserContext';
import { Context, createContext, PropsWithChildren } from 'react';

import {
  RetailAccessParamDto,
  RetailUserDto,
  useUserQuery
} from '~/apollo/gql-types';

export interface UserContext {
  isLoaded: boolean;
  id: string;
  permissions: Array<string> | null;
  accessParameters: Array<RetailAccessParamDto> | null;
  country: string;
}

export const UserContext: Context<UserContext> = createContext({
  isLoaded: false,
  id: null,
  permissions: null,
  accessParameters: null,
  country: null
});

// eslint-disable-next-line @typescript-eslint/ban-types
export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const { data } = useUserQuery();
  const user = data?.me as RetailUserDto;
  const isLoaded = Boolean(user?.id);

  if (!isLoaded) {
    return null;
  }

  return (
    <UserContextProvider
      value={{
        isLoaded,
        id: user?.id,
        permissions: user?.effectivePermissions,
        accessParameters: user?.accessParameters,
        country: user?.country
      }}
    >
      {children}
    </UserContextProvider>
  );
};
