import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useRequiredRules(required = true) {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        required,
        message: t('bo.common.form.field.required')
      }
    ],
    [required, t]
  );
}
