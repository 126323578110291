/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { useSearchSalesScoreboardResultsLazyQuery } from '~/apollo/gql-types';
import { PeriodType } from '~/constants/PeriodType';

import { PercentModel, SalesScoreboardModel, SearchFormModel } from '../types';

enum ValueKey {
  NetSignings = 'netSignings',
  SecondaryWheels = 'secondaryWheels'
}

const VALUE_TO_PERCENT: Record<string, string> = {
  financingSignings: 'financingPercentOfNetSignings',
  ppiInsuredFinancingSignings: 'ppiInsuredPercentOfNetFinancingSignings',
  gapInsuredFinancingSignings: 'gapInsuredPercentOfNetFinancingSignings',
  premiumWarranties: 'premiumWarrantiesPercentOfNetSignings',
  premium12Warranties: 'premium12WarrantiesPercentOfNetSignings',
  premium24Warranties: 'premium24WarrantiesPercentOfNetSignings',
  premium36Warranties: 'premium36WarrantiesPercentOfNetSignings',
  secondaryWheels: 'secondaryWheelsPercentOfNetSignings',
  tradeIns: 'tradeInsPercentOfNetSignings'
};
const VALUE_KEYS = ['score', 'cancelledSignings', ValueKey.NetSignings];

interface Props {
  team?: string[];
}

export function useDataSource({ team }: Props) {
  const [submit, { data, loading }] = useSearchSalesScoreboardResultsLazyQuery({
    notifyOnNetworkStatusChange: true
  });

  const dataSource = useMemo(() => {
    const keyToTotal = new Map<string, number>();

    const children: SalesScoreboardModel[] = data?.searchSalesScoreboardResults
      .filter(({ salesAgent }) =>
        team?.length > 0 ? team.includes(salesAgent?.team) : true
      )
      .map((x) => {
        const record = x as Record<string, number>;

        VALUE_KEYS.forEach((key) => {
          const value = record[key];
          keyToTotal.set(key, (keyToTotal.get(key) ?? 0) + value);
        });

        return {
          ...x,
          meta: Object.keys(VALUE_TO_PERCENT).reduce((prev, key) => {
            if (!Number.isFinite(record[key])) {
              return prev;
            }

            const value = record[key];

            keyToTotal.set(key, (keyToTotal.get(key) ?? 0) + (value ?? 0));

            return {
              ...prev,
              [key]: {
                value,
                percent: record[VALUE_TO_PERCENT[key]]
              }
            };
          }, {} as Record<string, PercentModel>)
        };
      });

    const parent: SalesScoreboardModel = {
      place: 0,
      ...VALUE_KEYS.reduce(
        (prev, key) => ({
          ...prev,
          [key]: keyToTotal.get(key)
        }),
        {}
      ),
      meta: Object.keys(VALUE_TO_PERCENT).reduce((acc, key) => {
        const value = keyToTotal.get(key);

        return {
          ...acc,
          [key]: {
            value,
            percent:
              key === ValueKey.SecondaryWheels
                ? null
                : Number(
                    Number(
                      (value / keyToTotal.get(ValueKey.NetSignings)) * 100
                    ).toFixed(2)
                  )
          }
        };
      }, {} as Record<string, PercentModel>)
    };

    return children?.length
      ? [
          {
            ...parent,
            children
          }
        ]
      : [];
  }, [data?.searchSalesScoreboardResults, team]);

  const onSubmit = useCallback(
    ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      team,
      date,
      countryCode,
      period,
      periodType,
      ...params
    }: SearchFormModel) => {
      submit({
        variables: {
          search: {
            ...params,
            periodType,
            period:
              periodType === PeriodType.DAILY && date
                ? Number(format(new Date(date), 'DDD'))
                : period,
            countryCodes: [countryCode]
          }
        }
      });
    },
    [submit]
  );

  return {
    dataSource,
    loading,
    onSubmit
  };
}
