import { SearchOutlined } from '@ant-design/icons';
import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { useUserContext } from '@retail/backoffice-ui/src/UserContext';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Row,
  Select
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { endOfYear, startOfYear } from 'date-fns';
import { Dispatch, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CohortType } from '~/constants/CohortType';
import { DATE_FORMAT } from '~/constants/date';
import { DOMAIN } from '~/constants/domain';
import { PeriodType } from '~/constants/PeriodType';
import { PERMISSION } from '~/constants/permission';
import { useRequiredRules } from '~/hooks/useRequiredRules';
import { UserContext } from '~/providers/UserProvider';

import { useCohortOptions } from '../hooks/useCohortOptions';
import { usePeriodOptions } from '../hooks/usePeriodOptions';
import { usePeriodTypeOptions } from '../hooks/usePeriodTypeOptions';
import { useSalesDepartmentTeamsOptions } from '../hooks/useSalesDepartmentTeamsOptions';
import { useYearOptions } from '../hooks/useYearOptions';
import { SearchFormModel } from '../types';

import { FieldCol } from './FieldCol';

const GAP = 16;

interface Props {
  loading: boolean;
  countryCodeOptions: DefaultOptionType[];
  form: FormInstance<SearchFormModel>;
  onSubmit: Dispatch<SearchFormModel>;
}

export function SearchForm({
  loading,
  countryCodeOptions,
  form,
  onSubmit
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const cohortOptions = useCohortOptions();
  const periodTypeOptions = usePeriodTypeOptions();
  const yearOptions = useYearOptions();
  const { options: salesDepartmentTeamsOptions, isLoading } =
    useSalesDepartmentTeamsOptions();
  const rules = useRequiredRules();
  const { country } = useUserContext<UserContext>();
  const periodType = Form.useWatch('periodType', form);
  const year = Form.useWatch('year', form);
  const periodOptions = usePeriodOptions(periodType);
  const initialValues = useMemo(
    (): SearchFormModel => ({
      countryCode: country,
      cohort: CohortType.VERIFIED,
      periodType: PeriodType.MONTHLY,
      period: null,
      team: [],
      year: new Date().getFullYear()
    }),
    [country]
  );

  return (
    <Card title={t('bo.salesScoreboard.searchForm.title')}>
      <Form
        form={form}
        disabled={loading}
        onFinish={onSubmit}
        data-qa="searchForm"
        layout="vertical"
        initialValues={initialValues}
      >
        <Row gutter={[GAP, GAP]} align="bottom">
          <FieldCol>
            <Form.Item
              name="countryCode"
              label={t('bo.salesScoreboard.searchForm.field.countryCode')}
              rules={rules}
            >
              <Select
                showSearch
                optionFilterProp="label"
                data-qa="countryCode"
                options={countryCodeOptions}
              />
            </Form.Item>
          </FieldCol>
          <PermissionChecker
            allow={PERMISSION.QUERY_USER_DEPARTMENTS_AND_TEAMS}
            domain={DOMAIN.USER_MANAGEMENT}
          >
            <FieldCol>
              <Form.Item name="team" label={t('bo.admin.userTeams.table.team')}>
                <Select
                  allowClear
                  showSearch
                  data-qa="team"
                  optionFilterProp="label"
                  mode="multiple"
                  placeholder={t('bo.common.field.placeholder.selectValue')}
                  maxTagCount={4}
                  options={salesDepartmentTeamsOptions}
                  loading={isLoading}
                />
              </Form.Item>
            </FieldCol>
          </PermissionChecker>
          <FieldCol>
            <Form.Item
              name="cohort"
              label={t('bo.salesScoreboard.searchForm.field.cohort')}
              rules={rules}
            >
              <Select data-qa="cohort" options={cohortOptions} />
            </Form.Item>
          </FieldCol>
          <FieldCol>
            <Form.Item
              name="periodType"
              label={t('bo.salesScoreboard.searchForm.field.periodType')}
              rules={rules}
            >
              <Select
                showSearch
                optionFilterProp="label"
                data-qa="periodType"
                options={periodTypeOptions}
              />
            </Form.Item>
          </FieldCol>
          <FieldCol>
            {periodType === PeriodType.DAILY ? (
              <Form.Item
                name="date"
                label={t('bo.salesScoreboard.searchForm.field.period')}
                rules={rules}
              >
                <DatePicker
                  data-qa="date"
                  format={DATE_FORMAT}
                  style={{ width: '100%' }}
                  disabledDate={(date) =>
                    !date.isBetween(
                      startOfYear(new Date(year, 0, 1)),
                      endOfYear(new Date(year, 0, 1))
                    )
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="period"
                label={t('bo.salesScoreboard.searchForm.field.period')}
                rules={rules}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  data-qa="period"
                  options={periodOptions}
                />
              </Form.Item>
            )}
          </FieldCol>
          <FieldCol>
            <Form.Item
              name="year"
              label={t('bo.salesScoreboard.searchForm.field.year')}
              rules={rules}
            >
              <Select
                showSearch
                optionFilterProp="label"
                data-qa="year"
                options={yearOptions}
              />
            </Form.Item>
          </FieldCol>
          <Col flex="auto">
            <Row justify="end" align="bottom">
              <Col span="auto">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  data-qa="submit"
                  icon={<SearchOutlined />}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
