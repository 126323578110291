// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QYqMzQxUSQuKOcWzn667 {\n  width: 100%;\n}\n.F_9_C50dwdmjoujpp9U5 {\n  display: flex;\n  justify-content: center;\n  gap: 16px;\n}\n.bWz5xWBlr50dJVyHBKUx.antd-4-21-7-table-row > td {\n  background-color: rgba(0, 0, 0, 0.02) !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/SalesScoreboard/styles.less"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;AAIA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;AAFF;AAME;EACE,gDAAA;AAJJ","sourcesContent":["@import '~styles/constants.less';\n\n.fullWidth {\n  width: 100%;\n}\n\n.headerButtons {\n  display: flex;\n  justify-content: center;\n  gap: 16px;\n}\n\n.selectedRow {\n  &:global(.@{antd-version}-table-row > td) {\n    background-color: rgba(0, 0, 0, 0.02) !important;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "QYqMzQxUSQuKOcWzn667",
	"headerButtons": "F_9_C50dwdmjoujpp9U5",
	"selectedRow": "bWz5xWBlr50dJVyHBKUx"
};
export default ___CSS_LOADER_EXPORT___;
