import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';

const START_YEAR = 2023;

export function useYearOptions() {
  const endYear = new Date().getFullYear() + 6;

  return useMemo(
    () =>
      Array.from({ length: endYear - START_YEAR }).map(
        (_, index): DefaultOptionType => {
          const value = index + START_YEAR;

          return {
            value,
            label: value
          };
        }
      ),
    [endYear]
  );
}
