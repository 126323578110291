import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PeriodType } from '~/constants/PeriodType';

export function usePeriodTypeOptions() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.values(PeriodType).map(
        (value): DefaultOptionType => ({
          value,
          label: t(`bo.salesScoreboard.periodType.${value}`)
        })
      ),
    [t]
  );
}
