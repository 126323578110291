import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserDepartmentsAndTeamsQuery } from '~/apollo/gql-types';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';

export function useSalesDepartmentTeamsOptions() {
  const { t } = useTranslation();
  const { isAllowed } = useCheckPermissions({
    allow: PERMISSION.QUERY_USER_DEPARTMENTS_AND_TEAMS,
    domain: DOMAIN.USER_MANAGEMENT
  });

  const { data, loading } = useUserDepartmentsAndTeamsQuery({
    context: {
      headers: { 'access-domain': DOMAIN.USER_MANAGEMENT }
    },
    skip: !isAllowed,
    fetchPolicy: 'cache-first'
  });

  const options = useMemo(() => {
    const { departments = [] } = data?.userDepartmentsAndTeams ?? {};

    return departments
      .filter(({ department }) => department === 'SALES')
      .map(({ teams }): DefaultOptionType[] =>
        teams.map(
          (team): DefaultOptionType => ({
            label: t(`bo.admin.userTeams.option.team.${team}`),
            value: team
          })
        )
      )
      .flat();
  }, [data?.userDepartmentsAndTeams, t]);

  return { options, isLoading: loading };
}
