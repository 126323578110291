import { TableCell } from '@retail/backoffice-ui/src/PaginatedTable/TableCell';
import { TableCellSpanProps } from '@retail/backoffice-ui/src/PaginatedTable/TableCellSpan';

import { PercentModel } from './types';

interface Props extends TableCellSpanProps, PercentModel {}

export function ValuePercentTableCell({ value, percent, ...props }: Props) {
  return (
    <TableCell {...props}>
      {[value, percent].every(Number.isFinite)
        ? `${value} (${percent}%)`
        : null}
    </TableCell>
  );
}
