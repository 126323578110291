import { DashboardTabs } from '@retail/backoffice-dashboard-tabs';
import { Layout, LayoutBody } from '@retail/backoffice-ui/src/Layout';
import { withPermissions } from '@retail/backoffice-ui/src/Permission/withPermissions';
import { useUserContext } from '@retail/backoffice-ui/src/UserContext';
import { Form, Space, Table } from 'antd';
import { getMonth, getWeek } from 'date-fns';
import { useEffect } from 'react';
import ReactHelmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { PeriodType } from '~/constants/PeriodType';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';

import { SearchForm } from './SearchForm';
import { useConfiguredColumns } from './hooks/useConfiguredColumns';
import { useCountryCodeOptions } from './hooks/useCountryCodeOptions';
import { useDataSource } from './hooks/useDataSource';
import cn from './styles.less';
import { SearchFormModel } from './types';

export const SalesScoreboard = withPermissions({
  allow: PERMISSION.QUERY_SEARCH_SALES_SCOREBOARD_RESULTS,
  domain: DOMAIN.SALES_SCOREBOARD_MANAGEMENT
})(() => {
  const { t } = useTranslation();
  const countryCodeOptions = useCountryCodeOptions();
  const { permissions } = useUserContext();
  const [form] = Form.useForm<SearchFormModel>();

  const countryCode = Form.useWatch('countryCode', form);
  const periodType = Form.useWatch('periodType', form);
  const year = Form.useWatch('year', form);
  const date = Form.useWatch('date', form);
  const team = Form.useWatch('team', form);
  const { dataSource, loading, onSubmit } = useDataSource({ team });

  const { columns, loading: columnsLoading } = useConfiguredColumns({
    countryCode,
    countryCodeOptions
  });

  useEffect(() => {
    if (periodType === PeriodType.DAILY && !date) {
      return;
    }

    /**
     * @description can be called twice because we change {@link period} when {@link periodType} is changed
     */
    const timer = setTimeout(() => onSubmit(form.getFieldsValue()));

    return () => clearTimeout(timer);
  }, [date, form, onSubmit, periodType]);

  useEffect(() => {
    const period =
      periodType === PeriodType.MONTHLY
        ? getMonth(new Date()) + 1
        : getWeek(new Date());

    form.setFieldsValue({ period });
  }, [form, periodType]);

  useEffect(() => {
    form.setFieldsValue({ date: null });
  }, [form, year]);

  return (
    <>
      <ReactHelmet title={t('bo.salesScoreboard.title')} />
      <DashboardTabs userPermissions={permissions} />
      <Layout>
        <LayoutBody>
          <Space direction="vertical" size="large" className={cn.fullWidth}>
            <SearchForm
              form={form}
              loading={loading}
              countryCodeOptions={countryCodeOptions}
              onSubmit={onSubmit}
            />
            <Table
              sticky
              size="small"
              key={dataSource.length}
              pagination={false}
              dataSource={columns.length ? dataSource : []}
              columns={columns}
              loading={loading || columnsLoading}
              scroll={{ x: 0 }}
              sortDirections={['descend', 'ascend']}
              rowKey={({ place, salesAgent, countryCode }) =>
                [place, salesAgent?.id, countryCode].filter(Boolean).join('-')
              }
              rowClassName={(row) =>
                row.children?.length ? cn.selectedRow : ''
              }
              expandable={{
                defaultExpandAllRows: true,
                expandIcon: () => <></>
              }}
            />
          </Space>
        </LayoutBody>
      </Layout>
    </>
  );
});
